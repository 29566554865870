import { FixedObject, FluidObject } from "gatsby-image";
import React from "react";
//import Img from 'gatsby-image/withIEPolyfill'
import {
  GatsbyImage as Img,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image";

import { ImageStyled } from "./styles";

interface IProps {
  fluid?: FluidObject | FluidObject[];
  fixed?: FixedObject | FixedObject[];
  objectPosition?: string;
  alt: string;
}

const Image: React.FC<IProps> = ({ fluid, fixed, objectPosition, alt }) => {
  if (!fluid && !fixed) {
    return null;
  }

  if (Array.isArray(fluid) && fluid.length === 0) {
    return null;
  }

  if (Array.isArray(fixed) && fixed.length === 0) {
    return null;
  }

  if (!Array.isArray(fluid) && fluid) {
    return null;
  }

  const images = getImages(
    fluid as FluidObject | FluidObject[],
    fixed as FixedObject | FixedObject[]
  );

  if (images === null) return null;

  return (
    <ImageStyled>
      <Img
        image={images}
        objectFit="cover"
        objectPosition={objectPosition}
        loading="eager"
        alt={alt || ""}
      />
    </ImageStyled>
  );
};

export default Image;

function getImages(
  unformattedFluid: FluidObject | FluidObject[],
  unformattedFixed: FixedObject | FixedObject[]
) {
  const fluid = Array.isArray(unformattedFluid)
    ? unformattedFluid.filter((image) => image)
    : unformattedFluid;

  if (fluid && !Array.isArray(fluid)) {
    return withArtDirection(getImage(fluid), [
      {
        media: "(max-width: 767px)",
        image: getImage(fluid as any),
      },
    ]);
  }

  if (fluid && Array.isArray(fluid) && fluid.length < 2) {
    return withArtDirection(getImage(fluid[0]), [
      {
        media: "(max-width: 767px)",
        image: getImage(fluid[0]),
      },
    ]);
  }

  if (fluid) {
    return withArtDirection(getImage(fluid[1]), [
      {
        media: "(max-width: 767px)",
        image: getImage(fluid[0]),
      },
    ]);
  }

  const fixed = Array.isArray(unformattedFixed)
    ? unformattedFixed.filter((image) => image)
    : unformattedFixed;

  if (fixed && !Array.isArray(fixed)) {
    return withArtDirection(getImage(fixed) as any, [
      {
        media: "(max-width: 767px)",
        image: getImage(fixed as any),
      },
    ]);
  }

  if (fixed && fixed.length < 2) {
    return withArtDirection(getImage(fixed[0]), [
      {
        media: "(max-width: 767px)",
        image: getImage(fixed[0]),
      },
    ]);
  }

  if (fixed) {
    return withArtDirection(getImage(fixed[1]), [
      {
        media: "(max-width: 767px)",
        image: getImage(fixed[0]),
      },
    ]);
  }

  return null;
}
